import * as React from "react";
import { motion, useCycle } from "framer-motion";
import { Link } from "gatsby";

import MenuToggle from "./menu-toggle";
import { MenuNavigation } from "./menu-navigation";

// https://www.youtube.com/watch?v=qvFLjZvz5Mw
const sidebar = {
  open: (height = 1000) => ({
    y:0,
    opacity: 1,
    transition: {
      from: 0,
      duration: .5,
    },
  }),
  closed: {
    y:0,
    opacity: 0,
    transition: {
      duration: .5
    },
    transitionEnd: {
      y:-9999
    },
  }
};

export const MenuSidebar = () => {

  const [menuAnimation, toggleHover] = useCycle("closed", "hover", "open");

  function checkMouseOverState() {
    if(menuAnimation != 'open') {
      {/* Set menuAnimation to hover */}
      toggleHover(1);
    }
  }

  function checkMouseOutState() {
    if(menuAnimation == 'hover') {
      {/* Set menuAnimation to closed */}
      toggleHover(0);
    }
  }

  function checkClickState() {
    if(menuAnimation != 'open') {
      {/* Set menuAnimation to open */}
      toggleHover(2);
    } else {
      {/* Set menuAnimation to closed */}
      toggleHover(0);
    }
  }

  return (

      <motion.nav
        className="main-nav"
        initial={false}
        animate={menuAnimation}
      >

      <motion.div className="nav-background" variants={sidebar}>
        <MenuNavigation toggle={() => checkClickState()} />
      </motion.div>

      <motion.div className="nav-title"><Link to="/">Anders Hojmose</Link></motion.div>

      <MenuToggle 
        toggle={() => checkClickState()} 
        mouseOver={() => checkMouseOverState()} 
        mouseOut={() => checkMouseOutState()} 
      />

    </motion.nav>
  );
};