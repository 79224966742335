import * as React from "react"
import { Link } from "gatsby"
import { motion } from "framer-motion"
import { MenuSidebar } from "./menu-sidebar/menu-sidebar"

if (typeof window !== "undefined") {
  // eslint-disable-next-line global-require
  require("smooth-scroll")('a[href*="#"]', {
    speed:1200
})
}

const Layout = ({ location, title, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = location.pathname === rootPath

  return (
    <div className="layout-site" data-is-root-path={isRootPath}>
    
    <MenuSidebar />
    
    <main>{children}</main>
    
      <footer class="pb-xl pt-xxl">
          <div className="layout-container">
              
            <div>
              <p class="list-title">© Anders Hojmose {new Date().getFullYear()}.</p>      
            </div>

              <div>
                <p class="list-title">
                  Content
                </p>
                <ul>
                  <li><Link to="/">Home</Link></li>
                  <li><Link to="/work">Work</Link></li>
                  <li><Link to="/about">About</Link></li>
                </ul>
                
              </div>
              <div>
              <p class="list-title">
                  Social
                </p>
                <ul>
                  <li><a href="https://www.linkedin.com/in/hojmose/" target="_blank" rel="noreferrer">LinkedIn</a></li>
                  <li><a href="https://www.instagram.com/everyoneelse/" target="_blank" rel="noreferrer">Instagram</a></li>
                  <li><a href="https://www.strava.com/athletes/15986819" target="_blank" rel="noreferrer">Strava</a></li>
                </ul>
                
              </div>

              <div>
              <p class="list-title">
                  Get in touch
                </p>
                <ul>
                  <li><a href="mailto:anders@hojmose.com" target="_self">anders@hojmose.com</a></li>
                </ul>

              </div>  
            </div>
        </footer>
    </div>
  )
}

export default Layout
