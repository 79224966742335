import * as React from "react";
import { motion } from "framer-motion";
import { Link } from "gatsby";

const variants = {
  open: {
    transition: { staggerChildren: 0.07, delayChildren: 0.2 }
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 }
  }
};

const itemVariants = {
    open: {
      y: 0,
      opacity: 1,
      transition: {
        y: { stiffness: 1000, velocity: -100 }
      }
    },
    closed: {
      y: 50,
      opacity: 0,
      transition: {
        y: { stiffness: 1000 }
      }
    }
  };

export const MenuNavigation = ({ toggle }) => (
    <motion.ul className="nav" variants={variants}>
        <motion.li variants={itemVariants}><Link to="/" onClick={toggle}>Home</Link></motion.li>
        <motion.li variants={itemVariants}><Link to="/work" onClick={toggle}>Work</Link></motion.li>
        <motion.li variants={itemVariants}><Link to="/about" onClick={toggle}>About</Link></motion.li>
    </motion.ul>
);