import * as React from "react";
import { motion } from "framer-motion";

const Path = props => (
  <motion.path
    fill="transparent"
    strokeWidth="2"
    strokeLinecap="butt"
    {...props}
  />
);

const dark = "#343a40";
const white = "#ffffff";

const variants = {
  open: { backgroundColor: white },
  hover: { backgroundColor: dark },
  closed: { backgroundColor: dark }
}

const MenuToggle = ({ toggle, mouseOver, mouseOut }) => (
<motion.button className="nav-toggle" onClick={toggle} onMouseEnter={mouseOver} onMouseLeave={mouseOut} variants={variants}>
  <svg width="24" height="24" viewBox="0 0 24 24">
    <Path
      variants={{
        closed: { d: "M 0 8 L 24 8", stroke: white },
        hover: { d: "M 0 7 L 24 7", stroke: white },
        open: { d: "M 3 21 L 21 3", stroke: dark },
      }}
    />
    <Path
      variants={{
        closed: { d: "M 0 16 L 24 16", stroke: white },
        hover: { d: "M 0 17 L 24 17", stroke: white },
        open: { d: "M 3 3 L 21 21", stroke: dark }
      }}
    />
  </svg>
</motion.button>
);

export default MenuToggle