exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-work-js": () => import("./../../../src/pages/work.js" /* webpackChunkName: "component---src-pages-work-js" */),
  "component---src-templates-project-template-jsx-content-file-path-content-work-albumm-index-mdx": () => import("./../../../src/templates/project-template.jsx?__contentFilePath=/opt/build/repo/content/work/albumm/index.mdx" /* webpackChunkName: "component---src-templates-project-template-jsx-content-file-path-content-work-albumm-index-mdx" */),
  "component---src-templates-project-template-jsx-content-file-path-content-work-cultural-heritage-explorations-index-mdx": () => import("./../../../src/templates/project-template.jsx?__contentFilePath=/opt/build/repo/content/work/cultural-heritage-explorations/index.mdx" /* webpackChunkName: "component---src-templates-project-template-jsx-content-file-path-content-work-cultural-heritage-explorations-index-mdx" */),
  "component---src-templates-project-template-jsx-content-file-path-content-work-daily-stack-index-mdx": () => import("./../../../src/templates/project-template.jsx?__contentFilePath=/opt/build/repo/content/work/daily-stack/index.mdx" /* webpackChunkName: "component---src-templates-project-template-jsx-content-file-path-content-work-daily-stack-index-mdx" */),
  "component---src-templates-project-template-jsx-content-file-path-content-work-ef-design-system-index-mdx": () => import("./../../../src/templates/project-template.jsx?__contentFilePath=/opt/build/repo/content/work/ef-design-system/index.mdx" /* webpackChunkName: "component---src-templates-project-template-jsx-content-file-path-content-work-ef-design-system-index-mdx" */),
  "component---src-templates-project-template-jsx-content-file-path-content-work-ef-gud-index-mdx": () => import("./../../../src/templates/project-template.jsx?__contentFilePath=/opt/build/repo/content/work/ef-gud/index.mdx" /* webpackChunkName: "component---src-templates-project-template-jsx-content-file-path-content-work-ef-gud-index-mdx" */),
  "component---src-templates-project-template-jsx-content-file-path-content-work-ef-parents-index-mdx": () => import("./../../../src/templates/project-template.jsx?__contentFilePath=/opt/build/repo/content/work/ef-parents/index.mdx" /* webpackChunkName: "component---src-templates-project-template-jsx-content-file-path-content-work-ef-parents-index-mdx" */),
  "component---src-templates-project-template-jsx-content-file-path-content-work-ef-pro-cycling-index-mdx": () => import("./../../../src/templates/project-template.jsx?__contentFilePath=/opt/build/repo/content/work/ef-pro-cycling/index.mdx" /* webpackChunkName: "component---src-templates-project-template-jsx-content-file-path-content-work-ef-pro-cycling-index-mdx" */),
  "component---src-templates-project-template-jsx-content-file-path-content-work-ef-studio-index-mdx": () => import("./../../../src/templates/project-template.jsx?__contentFilePath=/opt/build/repo/content/work/ef-studio/index.mdx" /* webpackChunkName: "component---src-templates-project-template-jsx-content-file-path-content-work-ef-studio-index-mdx" */),
  "component---src-templates-project-template-jsx-content-file-path-content-work-ef-virtual-office-index-mdx": () => import("./../../../src/templates/project-template.jsx?__contentFilePath=/opt/build/repo/content/work/ef-virtual-office/index.mdx" /* webpackChunkName: "component---src-templates-project-template-jsx-content-file-path-content-work-ef-virtual-office-index-mdx" */),
  "component---src-templates-project-template-jsx-content-file-path-content-work-hp-3-d-printing-index-mdx": () => import("./../../../src/templates/project-template.jsx?__contentFilePath=/opt/build/repo/content/work/hp-3d-printing/index.mdx" /* webpackChunkName: "component---src-templates-project-template-jsx-content-file-path-content-work-hp-3-d-printing-index-mdx" */),
  "component---src-templates-project-template-jsx-content-file-path-content-work-player-index-mdx": () => import("./../../../src/templates/project-template.jsx?__contentFilePath=/opt/build/repo/content/work/player/index.mdx" /* webpackChunkName: "component---src-templates-project-template-jsx-content-file-path-content-work-player-index-mdx" */)
}

